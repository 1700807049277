import "./homepage-v2.scss";
import "../styles/shared.scss";
import * as React from "react";
import clsx from "classnames";
import { graphql, StaticQuery } from "gatsby";
import {
  TypeHomepageV2PageQueryResult,
  getPage,
  TypeHomepageV2Quote,
} from "../utils/queries/homepagev2";
import { PrismicImage, PrismicRichText } from "../utils/queries/types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Linked from "../utils/linkedItem";
import { Carousel } from "react-responsive-carousel";
import Helmet from "react-helmet";
import { AmazeeIOButton } from "../components/button/button";
import AmazeeIoMetadata from "../components/metadata";
import RichTextRender from "../utils/rich-text";
import linkResolver from "../utils/linkResolver";
import AmazeePage from "../components/layout";
import usePreviewData from "../utils/usePreviewData";
import { Resource } from "../utils/queries/resources";
import {
  StandardCard,
  HomeCustomStandardCard,
  CustomStoriesCard,
} from "../templates/resources/cards";
import { HomepageLogoCarousel } from "../components/homepage-logos/carousel";
import { PageTitle } from "../components/title/title";

const HomepageFeature = ({
  image,
  title,
  details,
}: {
  image: PrismicImage;
  title: PrismicRichText | string;
  details: PrismicRichText;
}) => {
  // @ts-ignore
  const titleRendered = title.hasOwnProperty("raw") ? (
    RichTextRender(title)
  ) : (
    <h3 className="mb-4 text-2xl font-semibold">{title}</h3>
  );
  return (
    <div className="col-12 mb-10 col-lg-4 home-page-feature">
      <div className="icon">
        <img src={image.url} alt={image.alt} loading="lazy" />
      </div>
      <div className="title">{RichTextRender(titleRendered)}</div>
      <div className="description">{RichTextRender(details)}</div>
    </div>
  );
};

const HomepageFeature4 = ({
  image,
  title,
  details,
}: {
  image: PrismicImage;
  title: PrismicRichText | string;
  details: PrismicRichText;
}) => {
  // @ts-ignore
  const titleRendered = title.hasOwnProperty("raw") ? (
    RichTextRender(title)
  ) : (
    <h3 className="font-semibold text-2xl">{title}</h3>
  );
  return (
    <div className="col-12 mb-10 col-lg-3 home-page-feature wider">
      <div className="icon">
        <img src={image.url} alt={image.alt} loading="lazy" />
      </div>
      <div className="title">{RichTextRender(titleRendered)}</div>
      <div className="description">{RichTextRender(details)}</div>
    </div>
  );
};

export const QuoteCarouselHomePg = ({
  quotes,
  withComma = false,
}: {
  quotes: Array<TypeHomepageV2Quote>;
  withComma?: boolean;
}) => {
  return (
    <Carousel
      showArrows={true}
      showIndicators={false}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      useKeyboardArrows={true}
      autoPlay={true}
      interval={5000}
    >
      {quotes.map((quote, index) => (
        <div className="carousel-slide" key={index}>
          <div className="layover" />
          <div className="container">
            <div className="flex justify-items-center justify-content-center">
              <div className="col-12 col-lg-11 d-flex flex-column align-items-center">
                <div className="grid md:flex align-items-center mt-4">
                  <div className="quote-image">
                    {quote.image && (
                      <img
                        src={quote.image.url}
                        alt={quote.image.alt}
                        style={{ width: 210 }}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>

                <p className="quote">"{quote.text}"</p>

                <div className="author">
                  {/* <div className="quote-author-image">
                {quote.quote_author_image && (
                    <img
                      src={ quote.quote_author_image.url }
                      alt={quote.quote_author_image.alt}
                      style={{ width: 72 }}
                      loading="lazy"
                      />
                      )}
                    </div> */}
                  <div className="name md:text-left">
                    {quote.author_name}
                    {withComma ? "," : ""}
                  </div>
                  <div className="role md:text-left">
                    {RichTextRender(quote.author_title)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

const HeroSection: React.FC = ({ data }: { data: TypeHomepageV2PageData }) => {
  // const [showVideo, setShowVideo] = React.useState(false);
  const contactUsUrl = "/sign-up";
  const viewProductUsUrl = linkResolver({
    type: "page_definitions",
    slug: "product",
  });

  return (
    <div className="hero-content relative">
      <div className="container h-full">
        <div className="row h-full items-center">
          <div className="col-content fade-in-right h-full items-center">
            <div className="flex flex-col h-full w-full justify-center content">
              {RichTextRender(data.hero_text)}
              <div className="hero-subtile">
                {RichTextRender(data.hero_subtitle)}
              </div>
              <div className="hero-secondary-subtile">
                {data.hero_subtitle_secondary}
              </div>
              <div className="mt-2">
                <Linked link_to={{ document: { data: { url: contactUsUrl } } }}>
                  <AmazeeIOButton classes="inverted inline-block mt-4">
                    {data.cta_button_text}
                  </AmazeeIOButton>
                </Linked>
                <Linked
                  link_to={{ document: { data: { url: viewProductUsUrl } } }}
                >
                  <AmazeeIOButton classes="outline inline-block mt-4 text-white">
                    {data.cta_button_text_2}
                  </AmazeeIOButton>
                </Linked>
              </div>
              <div className="mobile-header-img">
                <img
                  src={data.hero_image_mobile.url}
                  alt={data.hero_image_mobile.alt}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          {/* <div
            className="relative header-video-graphic my-4 md:my-0 md:ml-8 md:mr-0 cursor-pointer"
            onClick={() => setShowVideo(true)}
          >
            {showVideo ? (
              <div className="iframe-wrapper">
                <iframe
                  className="media-youtube-player"
                  width="100%"
                  height="500px"
                  title="zeroOps"
                  src="https://www.youtube-nocookie.com/embed/FzPBJSwN3Pg?enablejsapi=1&autoplay=1&mute=0&controls=1&rel=0&loop=0&modestbranding&playlist=FzPBJSwN3Pg"
                  allowFullScreen={true}
                  allowscriptaccess="always"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            ) : (
              <>
                <div className="play-button-wrapper">
                  <img
                    className="play-button-passive"
                    src="/images/Video-Button-Passive.svg"
                    alt="play button passive"
                  />
                  <img
                    className="play-button-active"
                    src="/images/Video-Button-Active.svg"
                    alt="play button active"
                  />
                </div>
                <img
                  src={data.hero_video_image.url}
                  alt={data.hero_video_image.alt}
                  loading="lazy"
                />
              </>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

const IndexPage = ({
  data,
  location,
}: {
  data: TypeHomepageV2PageQueryResult;
  location: Location;
}) => {
  const edge = data.allPrismicHomepageV2.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(getPage(data), "prismicHomepage", true);

  const renderableResources = result.customer_stories.map(
    (rawResource) => new Resource(rawResource.case_study.document.data)
  );

  const renderableStoryResources = result.latest_stories.map(
    (rawResource) => new Resource(rawResource.story.document.data)
  );

  const [tabIndex, setTabIndex] = React.useState(0);
  const tabLength = result.tabs_group.length;
  const [showVideo, setShowVideo] = React.useState(false);

  const title = result.meta_title ? result.meta_title : result.title;

  console.log("title:", title);
  return (
    <AmazeePage location={location}>
      <div id="homepagev2">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
        </Helmet>
        <PageTitle title={title} />
        <AmazeeIoMetadata
          title={title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <HeroSection data={result} />

        <section id="logos" className="logos no-padding white desktop">
          <h2 className="section_header">{result.logos_section_header}</h2>
          <div className="container">
            <div className="row">
              <div className="col">
                <HomepageLogoCarousel itemsForTablet={2} logos={result.logos} />
              </div>
            </div>
          </div>
        </section>

        <section id="logos" className="logos no-padding white mobile">
          <h2 className="section_header">{result.logos_section_header}</h2>
          <div className="container">
            <div className="row">
              <div className="col">
                {result.logos.map((logomobi, index) => (
                  <div className="logo" key={index}>
                    <img
                      alt="mobile logo"
                      loading="lazy"
                      src={logomobi.logo.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section
          id="tabs"
          className="guarantees-intro tabs-header padding-bottom-small"
        >
          <div className="container mx-auto row justify-content-center">
            <div className="col-12 col-lg-10 text-center no-padding-mobile">
              <h2 className="section-title">{result.tabs_heading}</h2>
            </div>
            <div className="col-12 col-lg-7 text-center">
              <div className="subtitle">{result.tabs_subheading}</div>
            </div>
          </div>
        </section>

        <section className="guarantees py-0">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6 text-left">
                {result.guarantee_image && (
                  <img
                    className="w-full"
                    src={result.guarantee_image.url}
                    alt={result.guarantee_image.alt}
                    loading="lazy"
                  />
                )}
              </div>
              <div className="col-12 col-lg-4 mt-4 lg:mt-2">
                {RichTextRender(result.guarantee_copy)}
              </div>
            </div>
          </div>
        </section>

        <section id="tabs" className="tabs-section">
          <div className="text-center tabs-wrapper">
            <div className="tabs-outer tabs">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <div className="upper-controls pt-8">
                  <TabList>
                    {result.tabs_group.map((tab, index) => (
                      <Tab key={index}>
                        {tab.tab_heading}
                        <div className="line"></div>
                        <div className="arrow-down"></div>
                      </Tab>
                    ))}
                  </TabList>
                </div>
                <div className="background-dark-blue">
                  <div className="container">
                    <div className="justify-content-center">
                      {result.tabs_group.map((tab, index) => (
                        <TabPanel key={index}>
                          <h2 className="text-left tab-heading">
                            {tab.tab_heading}
                          </h2>
                          <div className="copy-holder">
                            <div className="leftside-copy">
                              {RichTextRender(tab.tab_copy_left)}
                            </div>
                            <div className="rightside-copy">
                              {RichTextRender(tab.tab_copy_right)}
                            </div>
                          </div>
                          <div className="lower-controls">
                            <TabList>
                              {result.tabs_group.map((tab, index) => (
                                <Tab
                                  key={index}
                                  selectedIndex={tabIndex}
                                  className={clsx(
                                    "react-tabs__tab",
                                    tabIndex + 1 === index
                                      ? "block next"
                                      : tabIndex - 1 === index
                                      ? "block prev"
                                      : tabIndex === tabLength - 1 &&
                                        index === 0
                                      ? "block next"
                                      : tabIndex === 0 &&
                                        index === tabLength - 1
                                      ? "block prev"
                                      : "is-hidden"
                                  )}
                                >
                                  {tab.tab_heading}
                                  <div className="line"></div>
                                  <div className="arrow-down"></div>
                                </Tab>
                              ))}
                            </TabList>
                          </div>
                        </TabPanel>
                      ))}
                    </div>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </section>

        <section id="features" className="features white">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="section-title mb-10">
                  {result.upper_info_blocks_heading}
                </h2>
                <div className="row align-items-start justify-center">
                  {result.features.map((feature, index) => (
                    <HomepageFeature
                      image={feature.image}
                      title={feature.title1.text}
                      details={feature.details}
                      key={index}
                    />
                  ))}
                </div>

                <div className="flex justify-center">
                  <div
                    className="col-12 col-xl-6 px-0 relative header-video-graphic my-4 md:my-0 cursor-pointer"
                    onClick={() => setShowVideo(true)}
                  >
                    {showVideo ? (
                      <div className="iframe-wrapper">
                        <iframe
                          className="media-youtube-player"
                          width="100%"
                          height="500px"
                          title="zeroOps"
                          src="https://www.youtube-nocookie.com/embed/FzPBJSwN3Pg?enablejsapi=1&autoplay=1&mute=0&controls=1&rel=0&loop=0&modestbranding&playlist=FzPBJSwN3Pg"
                          allowFullScreen={true}
                          allowscriptaccess="always"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    ) : (
                      <>
                        <div className="play-button-wrapper">
                          <img
                            className="play-button-passive"
                            src="/images/Video-Button-Passive.svg"
                            alt="play button passive"
                          />
                          <img
                            className="play-button-active"
                            src="/images/Video-Button-Active.svg"
                            alt="play button active"
                          />
                        </div>
                        <img
                          src={result.hero_video_image.url}
                          alt={result.hero_video_image.alt}
                          loading="lazy"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services white">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 col-lg-8 mb-6 text-center">
                <h2 className="section-title">{result.headline}</h2>
                <h2 className="section-title">{result.subheadline}</h2>
              </div>
              <div className="col-12 col-lg-7 text-center">
                <p className="section-subtitle">
                  {result.info_graphic_subheading}
                </p>
              </div>
            </div>
            <div className="row services justify-center items-center">
              <div className="col-12 col-lg-7 mt-8">
                <img
                  className="desktop"
                  src={result.services_main_image_left.url}
                  alt={result.services_main_image_left.alt}
                  loading="lazy"
                />
                <img
                  className="mobile"
                  src={result.infographic_image_mobile.url}
                  alt={result.infographic_image_mobile.alt}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="byo-code" className="byo-code white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8 text-center">
                <h2 className="title">{result.headline1}</h2>
                <div className="subtitle">
                  {RichTextRender(result.subtitle)}
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-12">
                <div className="row align-items-start text-center">
                  {result.bullets.map((feature, index) => (
                    <HomepageFeature4
                      image={feature.icon}
                      title={feature.title1}
                      details={feature.details}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="technologies" className="technologies no-padding medium">
          <h2 className="section_header mb-10">
            {result.technologies_heading}
          </h2>
          <div className="slider">
            <div
              className="slide-track1 mb-3"
              style={{
                width: `${result.technologies_logos_1.length * 217}px`,
              }}
            >
              {result.technologies_logos_1.map((logo, index) => (
                <div className="slide logo bg-white m-2" key={index}>
                  <img alt={""} src={logo.logo.url} />
                </div>
              ))}
            </div>

            <div
              className="slide-track2"
              style={{ width: `${result.technologies_logos_2.length * 217}px` }}
            >
              {result.technologies_logos_2.map((logo, index) => (
                <div className="slide logo bg-white m-2" key={index}>
                  <img alt={""} src={logo.logo.url} />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="case-studies" className="case-studies">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2 className="section-title mb-10 font-bold">
                  Modern businesses get big results with amazee.io’s Platform
                </h2>
                <div className="buttons mb-12">
                  <a href="/resources/customer-stories">
                    <div className="btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto mx-1">
                      View all customer stories
                    </div>
                  </a>
                  <a href="/sign-up">
                    <div className="btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inline-block width-auto mx-1">
                      Book a demo
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              {renderableResources.map((resource, index) =>
                resource.companyName ? (
                  <HomeCustomStandardCard resource={resource} key={index} />
                ) : (
                  <StandardCard resource={resource} key={index} />
                )
              )}
            </div>
          </div>
        </section>

        <section id="quotes">
          <div className="container">
            <div className="row">
              <div className="col-12 quote-carousel-section">
                <QuoteCarouselHomePg
                  withComma={true}
                  quotes={result.quotes.map(
                    (quote) =>
                      ({
                        image: quote.image,
                        text: quote.text,
                        author_name: quote.author_name,
                        author_title: quote.author_title,
                        quote_author_image: quote.quote_author_image,
                      } as TypeHomepageV2Quote)
                  )}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="customer-trust" id="customer_trust">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 d-flex justify-center flex-col items-center">
                <h4>
                  <div className="cta-main container col-md-12 cta-big">
                    Some of the reasons customers around the world love and
                    trust us.
                  </div>
                </h4>
                <a href="/resources/our-reviews" className="g2-badges">
                  <img src="https://amazeeio.cdn.prismic.io/amazeeio/ZozwOx5LeNNTw7Eu_High-Performer-Summer-2024.svg" />
                  <img src="https://amazeeio.cdn.prismic.io/amazeeio/ZozwOB5LeNNTw7Ep_Best-Support-Summer-2024.svg" />
                  <img src="https://amazeeio.cdn.prismic.io/amazeeio/ZozwOR5LeNNTw7Er_Easiest-to-do-Business-With-Summer-2024.svg" />
                  <img src="https://amazeeio.cdn.prismic.io/amazeeio/ZozwOh5LeNNTw7Es_Fastest-Implementation-Summer-2024.svg" />
                  <img src="https://amazeeio.cdn.prismic.io/amazeeio/ZozgCB5LeNNTw642_Most-Likely-To-Recommend-Summer-2024.svg" />
                  <img src="https://amazeeio.cdn.prismic.io/amazeeio/ZozgBx5LeNNTw64z_Best-Est-ROI-Summer-2024.svg" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="latest-news" id="case-studies">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-md-8 d-flex justify-center flex-col items-center">
                <h4>
                  <div className="cta-main container col-md-12 cta-big">
                    The latest from amazee.io
                  </div>
                </h4>
              </div>
            </div>

            <div className="row">
              {renderableStoryResources.map((resource, index) => (
                <CustomStoriesCard resource={resource} key={index} />
              ))}
            </div>
          </div>
        </section>

        <section className="cta larger-text" id="cta">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 d-flex justify-center flex-col items-center no-padding-mobile">
                <h4>
                  <div className="cta-main container col-md-12 cta-big">
                    {/* {result.cta} */}
                    <p>
                      Select the hosting success plan that suits you – <br />
                      Our cloud, your cloud infrastructure, or on-premises.{" "}
                      <br />
                      Switch to amazee.io today.
                      <br />
                    </p>
                  </div>
                </h4>
                <h4>
                  <div className="container col-md-7 cta-big">
                    {result.subtitle1}
                  </div>
                </h4>
                <div className="buttons">
                  <Linked link_to={result.button_link}>
                    <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto mt-4">
                      {result.button_text}
                    </AmazeeIOButton>
                  </Linked>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const IndexPageStatic: React.FC<{ location: Location }> = ({ location }) => {
  const query = graphql`
    {
      allPrismicHomepageV2 {
        edges {
          node {
            data {
              upper_info_blocks_heading
              info_graphic_subheading
              infographic_image_mobile {
                url
                alt
              }
              technologies_heading
              technologies_desktop {
                url
                alt
              }
              technologies_mobile {
                url
                alt
              }
              hero_image_mobile {
                url
                alt
              }
              hero_video_image {
                url
                alt
              }
              guarantee_image {
                url
                alt
              }
              guarantee_copy {
                html
                raw
                text
              }
              subheadline
              cta_button_text_2
              hero_subtitle_secondary
              button_text
              cta
              cta_button_text
              button_link {
                document {
                  ... on PrismicPageDefinitions {
                    id
                    data {
                      url
                      is_external
                    }
                  }
                }
              }
              second_button_text
              second_button_link {
                document {
                  ... on PrismicPageDefinitions {
                    id
                    data {
                      url
                      is_external
                    }
                  }
                }
              }
              quotes {
                text
                author_title {
                  html
                  raw
                  text
                }
                author_name
                quote_author_image {
                  alt
                  url
                }
                image {
                  alt
                  url
                }
              }
              logos_section_header
              headline
              headline1
              hero_bg_color
              hero_subtitle
              subtitle1
              title
              meta_title
              services_main_image_left {
                url
                alt
              }
              customer_stories {
                case_study {
                  document {
                    ... on PrismicResource {
                      id
                      data {
                        title
                        tags {
                          tag {
                            document {
                              ... on PrismicResourceIndustry {
                                id
                                data {
                                  industry
                                  display_in_filter_dropdown
                                }
                              }
                              ... on PrismicResourceTopic {
                                id
                                data {
                                  topic
                                  display_in_filter_dropdown
                                }
                              }
                            }
                          }
                        }
                        post_date
                        featured_on_customer_page
                        description {
                          html
                          raw
                          text
                        }
                        company_name
                        featured
                        link {
                          url
                          document {
                            ... on PrismicCaseStudy {
                              id
                              uid
                            }
                          }
                        }
                        image {
                          alt
                          url
                        }
                        resource_type {
                          document {
                            ... on PrismicResourceType {
                              id
                              data {
                                display_in_filter_dropdown
                                type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              latest_stories {
                story {
                  document {
                    ... on PrismicResource {
                      id
                      data {
                        title
                        tags {
                          tag {
                            document {
                              ... on PrismicResourceIndustry {
                                id
                                data {
                                  industry
                                  display_in_filter_dropdown
                                }
                              }
                              ... on PrismicResourceTopic {
                                id
                                data {
                                  topic
                                  display_in_filter_dropdown
                                }
                              }
                            }
                          }
                        }
                        post_date
                        featured_on_customer_page
                        description {
                          html
                          raw
                          text
                        }
                        company_name
                        featured
                        link {
                          url
                          document {
                            ... on PrismicCaseStudy {
                              id
                              uid
                            }
                          }
                        }
                        image {
                          alt
                          url
                        }
                        resource_type {
                          document {
                            ... on PrismicResourceType {
                              id
                              data {
                                display_in_filter_dropdown
                                type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              services {
                direction
                details {
                  text
                  raw
                  html
                }
                image {
                  url
                  dimensions {
                    height
                    width
                  }
                }
                title1
                read_more {
                  id
                  link_type
                  slug
                  type
                  document {
                    ... on PrismicPageDefinitions {
                      id
                      data {
                        url
                        is_external
                      }
                    }
                  }
                }
              }
              subtitle {
                text
                raw
                html
              }
              tabs_heading
              tabs_subheading
              tabs_group {
                tab_heading
                tab_id
                tab_copy_left {
                  text
                  raw
                  html
                }
                tab_copy_right {
                  text
                  raw
                  html
                }
              }
              logos {
                logo {
                  url
                }
              }
              technologies_logos_1 {
                logo {
                  url
                }
              }
              technologies_logos_2 {
                logo {
                  url
                }
              }
              hero_text {
                text
                raw
                html
              }
              hero_image {
                url
              }
              features {
                title1 {
                  text
                  raw
                  html
                }
                image {
                  url
                }
                details {
                  text
                  raw
                  html
                }
              }
              bullets {
                title1
                icon {
                  url
                }
                details {
                  text
                  raw
                  html
                }
              }
              page_meta_description
              page_meta_thumbnail {
                url
              }
            }
          }
        }
      }
    }
  `;
  return (
    <StaticQuery
      render={(result: TypeHomepageV2PageQueryResult) => (
        <IndexPage location={location} data={result} />
      )}
      query={`${query}`}
    />
  );
};
export default IndexPageStatic;
